.team-sectio {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0; 
  margin: 0;
  padding: 0;
  min-height: 100vh; 
  display: flex;
  justify-content: center; 
  align-items: center;
  flex-direction: column;

  .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
  }

  .team {
    display: flex;
    justify-content: center;
    gap: 20px; 
    flex-wrap: wrap; 
    padding: 20px;
  }

  .card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 250px;
    text-align: center;
    overflow: hidden;
  }

  .card img {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #eee;
  }

  .card-info {
    padding: 15px;
  }

  .card-info h3 {
    font-size: 20px;
    margin-bottom: 5px;
  }



  .social {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
  }

}



@media (max-width: 480px) {
  .card {
    width: 100%;
  }

  .title {
    font-size: 18px;
    margin-bottom: 10px; 
  }
}